import React, { Component, useState } from 'react';
import Navbar from './components/NavBar';
//import MainContainer from './containers/MainContainer'
//import Header from './components/Header'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Logout from "./components/Logout";
import Verbrauch from "./pages/Verbrauch";
import Nebekosten from './pages/Nebenkosten';
import AbrechnungVerbrauch from './pages/AbrechnungVerbrauch';
import Gebauede from './pages/Gebauede';
import Mieter from './pages/Mieter';

import Login from './components/Login';
//import Contact from "./pages/Contact";
//import NoPage from "./pages/NoPage";
import UseToken from "./components/UseToken";



export default function App() {

    const { token, setToken } = UseToken();

    if (!token) {
        return <Login setToken={setToken} />
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="home" element={<Home/>}/>
                    {/*<Route path="HaushaltsfuehrungsSchaden" element={<HaushaltsfuehrungsSchaden/>}/>*/}
                    <Route path="/action/3.1" element={<Verbrauch />} />
                    <Route path="/action/3.2" element={<Nebekosten />} />
                    <Route path="/action/3.3" element={<AbrechnungVerbrauch />} />
                    <Route path="/action/3.4" element={<Gebauede />} />
                    <Route path="/action/3.5" element={<Mieter />} />
                    {/*<Route path="contact" element={<Contact />} />*/}
                    <Route path="/" element={<Login setToken={setToken} />} />
                    <Route path="/Logout" element={<Logout setToken={setToken} />} />
                    <Route path="/*" element={<Home />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

//function App() {
//    return (
//        <div className="App">
//            <Header />
//            <Navbar />
//            <MainContainer />
//        </div>
//    );
//}

//export default App;

//export default class App extends Component {
//    static displayName = App.name;

//    constructor(props) {
//        super(props);
//        this.state = { forecasts: [], loading: true };
//    }

//    componentDidMount() {
//        this.populateWeatherData();
//    }

//    static renderForecastsTable(forecasts) {
//        return (
//            <table className='table table-striped' aria-labelledby="tabelLabel">
//                <thead>
//                    <tr>
//                        <th>Date</th>
//                        <th>Temp. (C)</th>
//                        <th>Temp. (F)</th>
//                        <th>Summary</th>
//                    </tr>
//                </thead>
//                <tbody>
//                    {forecasts.map(forecast =>
//                        <tr key={forecast.date}>
//                            <td>{forecast.date}</td>
//                            <td>{forecast.temperatureC}</td>
//                            <td>{forecast.temperatureF}</td>
//                            <td>{forecast.summary}</td>
//                        </tr>
//                    )}
//                </tbody>
//            </table>
//        );
//    }

//    render() {
//        let contents = this.state.loading
//            ? <p><em>Loading... Please refresh once the ASP.NET backend has started. See <a href="https://aka.ms/jspsintegrationreact">https://aka.ms/jspsintegrationreact</a> for more details.</em></p>
//            : App.renderForecastsTable(this.state.forecasts);

//        return (
//            <div>
//                <h1 id="tabelLabel" >Weather forecast</h1>
//                <p>This component demonstrates fetching data from the server.</p>
//                {contents}
//            </div>
//        );
//    }

//    async populateWeatherData() {
//        const response = await fetch('weatherforecast');
//        const data = await response.json();
//        this.setState({ forecasts: data, loading: false });
//    }
//}
