import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from './NavBar'
import UseToken from "./UseToken"

import Home from "../pages/Home";
const Logout = () => {

    window.localStorage.removeItem("token");
    const { token, setToken } = UseToken();
    window.location.href = '/';

    return (
        <h1>{Logout}</h1>
    );
};

export default Logout;