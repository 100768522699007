import React, { useEffect, useState } from 'react';
import styles from "./Verbrauch.module.css";

async function submitVerbrauch(zaehlerstaende) {
    return fetch('/api/v1/Verbrauch', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(zaehlerstaende)
    })
        .then(data => data.json())
}

function componentDidMount() {
    document.getElementById('datePicker').value = new Date().toISOString("yyyyMMdd").substr(0, 10);
}

function Verbrauch() {

    useEffect(() => { componentDidMount() }, []);

    const [datum, setDatum] = useState(new Date().toISOString("yyyyMMdd").substr(0, 10));
    const [zaehlerstandGas, setZaehlerstandGas] = useState('-1');
    const [zaehlerstandWasser, setZaehlerstandWasser] = useState('-1');
    const [zaehlerstandstromOben, setzaehlerstandstromOben] = useState('-1');
    const [zaehlerstandstromUnten, setzaehlerstandstromUnten] = useState('-1');

    const handleSubmit = async e => {
        e.preventDefault();
        const result = await submitVerbrauch({
            datum,
            zaehlerstandGas,
            zaehlerstandWasser,
            zaehlerstandstromOben,
            zaehlerstandstromUnten
        });
    }

    return (

        <>
        <h1>Verbrauch</h1>

            <p>Zählerstände</p>
            <form onSubmit={handleSubmit}>

                <label className={styles.label} htmlFor="datePicker">Datum</label>
                <input className={styles.input} type="date" id="datePicker" name="datePicker" defaultValue="2022-01-01" onChange={e => setDatum(e.target.value)} /><br></br>

                <label className={styles.label} htmlFor="gas-value">Gas (qm3)</label>
                <input className={styles.input} type="number" step="1.0" min="0" max="100" id="gas-value" name="gas-value" defaultValue="0" size="6" onChange={e => setZaehlerstandGas(e.target.value)} /><br></br>

                <label className={styles.label} htmlFor="wasser-value">Wasser (qm3)</label>
                <input className={styles.input} type="number" step="1.0" id="wasser-value" name="wasser-value" defaultValue="0" size="6" onChange={e => setZaehlerstandWasser(e.target.value)} /><br></br>

                <label className={styles.label} htmlFor="zaehlerstandstromOben-value">Strom Oben (kwh)</label>
                <input className={styles.input} type="number" step="1.0" id="zaehlerstandstromOben-value" name="zaehlerstandstromOben-value" defaultValue="0" size="6" onChange={e => setzaehlerstandstromOben(e.target.value)} /><br></br>

                <label className={styles.label} htmlFor="zaehlerstandstromUnten-value">Strom Unten (kwh)</label>
                <input className={styles.input} type="number" step="1.0" id="zaehlerstandstromUnten-value" name="zaehlerstandstromUnten-value" defaultValue="0" size="6" onChange={e => setzaehlerstandstromUnten(e.target.value)} /><br></br>


                <div className="login-div">
                    <button className={styles.button} type="submit">Submit</button>
                </div>
            </form>
        </>
    );
}

export default Verbrauch;