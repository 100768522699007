import React, { useEffect, useState } from 'react';
import { render } from '../../node_modules/react-dom/cjs/react-dom.development';
import styles from "./Gebauede.module.css";

class Gebauede extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], datum: "" };
    }

    componentDidMount() {
        fetch('/api/v1/Gebauede/0', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => data.json())
        .then(data => {
            var jsonAfterStringify = JSON.stringify(data);
            var jsonObject = JSON.parse(jsonAfterStringify);
            for (var i = 0; i < data.length; i++) {
                data[i] = JSON.parse(data[i]);
            }
            this.setState({ data: data })
        })

        //const _datum = String(document.getElementById('datePicker').value);
        //this.setState({ datum: _datum })
    }

    submitNebenkosten() {
        const datum = this.state.datum;
        const positionsList = this.state.data.map(item => JSON.stringify(item))
        return fetch('/api/v1/Gebauede', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ datum, positionsList })
        })
            .then(data => data.json())
    }

    loadNebenkosten(year) {
        return fetch('/api/v1/Gebauede/' + year, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                var jsonAfterStringify = JSON.stringify(data);
                var jsonObject = JSON.parse(jsonAfterStringify);
                for (var i = 0; i < data.length; i++) {
                    data[i] = JSON.parse(data[i]);
                }
                this.setState({ data: data })
            })
    }

    handleAdd() {
        const _id = String(document.getElementById('newPosition').value).toLowerCase();
        const _index = this.state.data.length;
        const _position = document.getElementById('newPosition').value;
        const newList = this.state.data.slice();
        newList.push({ id: _id, index: _index, position: _position, quantity: 1, unit: '', costs: "0.00" });
        this.setState({ data:newList });
    }

    handleRemove(id) {
        const newList = this.state.data.filter((item) => item.id !== id);
        this.setState({ data: newList });
    }

    handleChange(_item) {
        const _id = String(document.getElementById('newPosition').value).toLowerCase();
        const _position = document.getElementById('newPosition').value;
        const index = this.state.data.findIndex((item) => item.id === _item.id);
        const newList = this.state.data;
        newList[index] = { id: _id, index: _item.index, position: _position, quantity: _item.quantity, unit: _item.unit, costs: _item.costs };
        this.setState({ data: newList });
    }

    handleUpdateUnit(_item, value) {
        const index = this.state.data.findIndex((item) => item.id === _item.id);
        this.state.data[index] = {
            id: _item.id,
            index: _item.index,
            position: _item.position,
            quantity: _item.quantity,
            unit: value,
            costs: _item.costs
        }
    }

    handleUpdateQuantity(_item, value) {
        const index = this.state.data.findIndex((item) => item.id === _item.id);
        this.state.data[index] = {
            id: _item.id,
            index: _item.index,
            position: _item.position,
            quantity: _item.quantity,
            unit: _item.unit,
            costs: _item.costs
        }
    }

    handleUpdateCosts(_item, value) {
        const index = this.state.data.findIndex((item) => item.id === _item.id);
        this.state.data[index] = {
            id: _item.id,
            index: _item.index,
            position: _item.position,
            quantity: _item.quantity,
            unit: _item.unit,
            costs: value
        }
    }

    onYearChanged(e) {
        e.preventDefault();
        const year = e.target.value;
        this.setState({datum: year});
        this.handleLoadYearData(year);
    }

    handleLoadYearData(year) {
        var newList = []
        const result = this.loadNebenkosten(year);
        //var jsonStr = JSON.parse(result);
        newList.push(result);
        this.setState({ data: newList });
    }

    handleSubmit = async e => {
        e.preventDefault();
        const result = this.submitNebenkosten();
    }

    render() {
        return (

            <>
                <h1>Gebäude</h1>

               
            </>
        );
    }
}

export default Gebauede;