import React from 'react';
import { render } from '../../node_modules/react-dom/cjs/react-dom.development';
import styles from "./AbrechnungVerbrauch.module.css";

class AbrechnungVerbrauch extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], datum: "" };
    }

    componentDidMount() {
        var year = '2022';
        fetch('/api/v1/Nebenkosten/' + year, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                var jsonAfterStringify = JSON.stringify(data);
                var jsonObject = JSON.parse(jsonAfterStringify);
                for (var i = 0; i < data.length; i++) {
                    data[i] = JSON.parse(data[i]);
                }
                this.setState({ data: data })
            })

        const _datum = String(document.getElementById('datePicker').value);
        this.setState({ datum: _datum })
    }

    loadNebenkosten(year) {
        return fetch('/api/v1/Nebenkosten/' + year, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                var jsonAfterStringify = JSON.stringify(data);
                var jsonObject = JSON.parse(jsonAfterStringify);
                for (var i = 0; i < data.length; i++) {
                    data[i] = JSON.parse(data[i]);
                }
                this.setState({ data: data })
            })
    }

    onYearChanged(e) {
        e.preventDefault();
        const year = e.target.value;
        this.setState({ datum: year });
        this.handleLoadYearData(year);
    }

    handleLoadYearData(year) {
        var newList = []
        const result = this.loadNebenkosten(year);
        //var jsonStr = JSON.parse(result);
        newList.push(result);
        this.setState({ data: newList });
    }

    render() {
        return (

            <>
                <h1>Nebenkosten</h1>

                <form onSubmit={this.handleSubmit}>

                    <label className={styles.label} htmlFor="datePicker">Jahr</label>
                    <input className={styles.input} type="number" id="datePicker" name="datePicker" defaultValue="2022" onChange={e => this.onYearChanged(e)} /><br></br>
                    <br></br>
                    <p>Positionen</p>
                    <ul>
                        {this.state.data.map((item) => (
                            <span className="" key={item.id}>
                                {/*<table>*/}
                                {/*    <tr className="row">*/}
                                {/*        <th>{item.position}</th>*/}
                                {/*        <th>{item.costs}</th>*/}
                                {/*        <th>{item.quantity}</th>*/}
                                {/*        <th>{item.unit}</th>*/}
                                {/*    </tr>*/}

                                {/*</table>*/}
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className={styles.row}>{item.position}</td>
                                        <td className={styles.col}>{item.costs} &euro;</td>
                                        <td className={styles.col}>{item.quantity} {item.unit}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </span>
                           
                        ))}
                    </ul>*


                    <div className="login-div">
                        <button className={styles.button} type="submit">Submit</button>
                    </div>
                </form>


            </>
        );
    }
}

export default AbrechnungVerbrauch;